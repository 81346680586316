
.oneonone-detail-container {
  .oneonone-name {
    height: 32px;
    line-height: 32px;
    
  }
  h3 {
    margin-top: 1em;
  }
  .add-todo {
    display: inline-block;
    flex: 2;
    .todo-item {
      padding: 0;
      font-size: 10pt;
    }
  }

  .agenda-container {
    .agenda-list, .create {
      margin-left: 1em;
    }
  }
}

