.oneonone-list-container {
  ul {
    margin: 0;
    padding-left: 16px;
  }
  li {
    list-style-image: url(/images/bullet.png);
    font-size: 10pt;
    line-height: 1.2em;
  }

  .last-update {
    margin-left: 1em;
  }
  .todo-count {
    margin-left: 1em;
    font-style: italic;
  }
  .agenda-count {
    margin-left: 1em;
    font-style: italic;
  }

  &.compact {

  }

  .one-on-one-item {
    &.create {
      margin-top: 1em;
    }
  } 
}
