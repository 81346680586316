
.journal-entry {
  border: solid 1px #ccc;
  border-radius: 3px;
  margin-top: 1em;
  padding: 0.5em;

  .log-date {
    font-size: 8pt;
    font-style: italic;
    height: 14px;

    .edit-journal {
      display: none;
    }
    .edit-journal, .stop-edit-journal {
      border: none;
      cursor: pointer;
    }
  }

  &:hover {
    .log-date {
      .edit-journal {
        display: inline;
      }
    }
  }
}

.journal-content {

  h1 {
    font-size: 12pt;
  }
  h2 {
    font-size: 10pt;
  }
  h3 {
    font-size: 10pt;
    font-weight: normal;
  }

  .editable-markdown-container {
    margin-top: 1em;
  }
}
