.editable-text-field-form {
  margin: 0 0 4px 0;
  padding: 0;
  flex: 2;
  display: flex;

  .editable-text-field {
    border: none;
    flex: 2;
    height: 1.5em;
    padding: 0;
    padding-bottom: 1px;
    background: none;
    resize: none;

    &:focus {
      border-bottom: solid 1px #ccc;
      padding-bottom: 0;
    }

    &.empty {
      border-bottom: solid 1px #ccc;
      padding-bottom: 0;
      font-style: italic;
    }
  }

  button.submit-button {
    padding: 2px;
    background-color: #8c8;
    display: inline-block;
    border: solid 1px #aaa;
    border-radius: 3px;
    cursor: pointer;
  }
}