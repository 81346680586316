div.loading {
    width: 32px;
    height: 32px;
    background-image: url(/images/compass-icon.png);
    background-size: contain;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    0% {
        transform:rotate(0deg);
    }
    40% {
        transform:rotate(300deg);
    }
    60% {
        transform:rotate(270deg);
    }
    100% {
        transform:rotate(360deg);
    }
}
