.agenda-item {
    display: flex;

    &.create {
      margin-top: 1em;
    }

    .finish-agenda {
      display: inline-block;
      padding: 2px;
      background-color: #eee;
      display: inline-block;
      border: solid 1px #aaa;
      border-radius: 3px;
      width: 20px;
      cursor: pointer;
      margin-right: 1em;
    }
  }
