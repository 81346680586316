$mobile-layout-break: 900px;

.drag-handle {
  cursor: grab;
  margin-right: 4px;
  user-select: none;
}

@media only screen and (max-width: $mobile-layout-break) {
  .drag-handle {
    margin-right: 12px;
  }
}

.todo-item {
  width: 100%;
  padding: 0;
  display: flex;
  font-size: 10pt;

  .ignored {
    color: red;
  }
  .stale {
    color: orange;
  }
  .old-todo-action {
    flex: none;
    width: 5.5em;
    height: 20px;
    margin-left: 0.5em;
    select {
      padding: 0 18px 0 0;
      font-size: 10pt;
      line-height: 12px;
    }
  }

  &.read-only {
    background: url(/images/bullet.png) no-repeat left;
  }

  &.create {
    margin-top: 1em;
  }

  .todo-one-on-one, .todo-promisee {
    font-style: italic;
    margin-right: 0.5em;
    display: inline-block;
  }
 
  .completed-todo {
    text-decoration: line-through;
    height: 1.5em;
    margin-top: 2px;
  }

  .read-only {
    height: 1.2em !important;
    margin: 0 !important;
  }

  form {
    flex: 2;
    display: flex;
  }

  .add-todo-form {
    margin: 1em 0;
  }

  .edit-todo-item, .create-todo-item {
    border: none;
    flex: 2;
    height: 1.5em;
    padding: 0;
    padding-bottom: 1px;
    background: none;

    &:focus {
      border-bottom: solid 1px #ccc;
      padding-bottom: 0;
    }
  }
  .create-todo-item {
    border-bottom: solid 1px #ccc;
    padding-bottom: 0;
    font-style: italic;
  }
}