$mobile-layout-break: 900px;


.section {
  padding: 0.5em;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  
  h2 {
    border-bottom: solid 1px #aaa;
  }
}

/* Mobile Styles */
@media only screen and (max-width: $mobile-layout-break) {
  .section {
    border: none;
    margin-top: 1em;
    padding: 0;
    width: 100%;
    display: block;

  }

  div.app-content {
    margin: 0;
  }

  p {
    margin: 0.25em 0;
  }
}
