$mobile-layout-break: 900px;

.todo-item {
  input[type="checkbox"] {
    // transform:scale(1.3, 1.3);
    margin-right: 4px;
    user-select: none;
  }
}

@media only screen and (max-width: $mobile-layout-break) {
  .todo-item {
    input[type="checkbox"] {
      margin-right: 12px;
    }
  }
}