.team-list-container {
  ul {
    margin: 0;
    padding-left: 16px;
  }
  li {
    list-style-image: url(/images/bullet.png);
    font-size: 10pt;
    line-height: 1.2em;
  }
  &.compact {

  }
}
