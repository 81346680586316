$mobile-layout-break: 900px;

.navmenu {
  background-image: url(/images/compass-icon.png);
  background-position: left center;
  background-repeat: no-repeat;

  padding: 0 0 0 30px;
  margin: 0;

  height: 32px;
  vertical-align: middle;

  li {
    height: 32px;
    vertical-align: middle;

    display: inline-block;
    list-style: none;
    margin: 0 1em;
    border-left: solid 3px #aaa;
    padding-left: 3px;

    &:first-child {
      border: none;
    }

    &.no-separator {
      border: none;
      margin: 0;
    }

    a {
      text-decoration: none;
      color: #666;
      line-height: 32px;
      vertical-align: middle;
      font-weight: bold;

      &:hover {
        color: #333;
        text-decoration: underline;
      }

      &.active {
        color: #66e;
      }

      &.refresh {
        font-size: 16pt;
        text-transform: capitalize;
      }
    }
  }
  
  button {
    color: #666;
    padding: 9px 0 0 0;
    line-height: 32px;
    vertical-align: middle;
    &:hover {
      background: none;
    }
  }
}

@media (max-width: 800px) {
  .navmenu {  }
  .navmenu li { display: none; z-index: 1001; }
  // .navmenu:before { content: "\2261"; font-size: 20pt; }
  .navmenu.visible li { display: inline-block;  }
}
