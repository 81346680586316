
.about-modal {
  h2 {
    img.logo {
      position: relative;
      top: 9px;
    }
  }
  .action-bar {
    text-align: right;
  }
}