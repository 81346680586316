.action-bar {
  text-align: right;

  &.destructive {
    text-align: center;
    border: solid 1px red;
    border-radius: 3px;
    padding: 0.5em;

    &:hover {
      background-color: red;
    }
  }
}

div > span.edit-one-on-one {
  margin-left: 1em;
  .start-edit {
    display: none;
  }
}
div:hover > span.edit-one-on-one > .start-edit {
  display: inherit;
}
