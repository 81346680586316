form.signin {
  input[type="text"], input[type="password"], input[type="submit"] {
    display: block;
    margin: .5em;
    border: solid 1px #aaa;
    border-radius: 3px;
  }

  input[type="submit"] {
    cursor: pointer;
    display: inline-block;
  }

  .toggle-mode {
    text-decoration: none;
    color: #666;
    cursor: pointer;
    font-size: 9pt;
    padding: 1em;

    &:hover {
      color: #333;
      text-decoration: underline;
    }
  }
}

div.error {
  color: #c22;
  font-style: italic;
  margin: 2em;
  border: solid 1px #c22;
  padding: 1em;
  background-color: #ccc;
}